import React from "react";
import CaseLeft from "./CaseLeft";

const CaseRoll = (props) => {
  const { edges: cases } = props.cases;

  return (
    <div>
      {cases.map(({ node: BusinessCase }) => (
        <CaseLeft
          props={props}
          url={BusinessCase.fields.slug}
          button_url={BusinessCase.frontmatter.button_url}
          button_cta={BusinessCase.frontmatter.button_cta}
          title={BusinessCase.frontmatter.title}
          description={BusinessCase.frontmatter.description}
          clientlogo={BusinessCase.frontmatter.clientlogo}
          featuredimage={BusinessCase.frontmatter.featuredimage}
          key={BusinessCase.fields.slug}
        />
      ))}
    </div>
  );
};

export default CaseRoll;
